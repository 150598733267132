.btn {
  .btn-inner--icon {
    margin-right: 4px;
  }
}
.btn + .btn {
  margin-left: 4px;
}

.mainCardHeader {
  .title {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
  .status {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
  }
  .text {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
  }
}
.card {
  .parent {
    display: flex;

    .cardTitle {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
    }
    .cardQuantity {
      font-size: 12px;
      font-weight: normal;
    }
    .status {
      font-size: 12px;
      font-weight: 300;
    }
  }
}

.custom-file-upload {
  cursor: pointer;
  padding: 8px 15px;
  background-color: #5e72e4;
  color: #fff;
  border-radius: 5px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
}

.custom-file-upload:hover {
  background-color: #5c6fd9;
}
@media (max-width: 576px) {
  .card {
    padding-bottom: 20px;
  }
  .OrderCardmainDiv {
    display: block !important;
    .subDiv {
      margin: 0 !important;
      margin-top: 20px !important;
    }
  }
  .badge {
    margin-left: 4px !important;
  }
}
