@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:wght@400;500;600&display=swap");

* {
  font-family: "Pacifico", cursive;
  font-family: "Poppins", sans-serif;
}
.svg-mbl {
  @media (max-width: 575px) {
    width: 150px !important;
  }
}

// Review Dispute

.review_Dispute {
  .review {
    width: 200px;
    height: 40px;
    white-space: normal;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;

      &-thumb {
        background: #5e72e4;
        border-radius: 12px;
      }

      &-track {
        background: rgba(203, 203, 203, 0.703);
        border-radius: 12px;
      }
    }
  }
  .rating{
    white-space: normal;
    width: 70px;
  }
}

.ant-segmented-item {
  margin: 0.25rem;
}

//Posts

.detail {
  width: 400px;
  height: 50px;
  white-space: normal;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;

    &-thumb {
      background: #5e72e4;
      border-radius: 12px;
    }

    &-track {
      background: rgba(203, 203, 203, 0.703);
      border-radius: 12px;
    }
  }
}

.truncate {
  overflow: hidden;
  max-width: 15rem;
  text-overflow: ellipsis;
}
